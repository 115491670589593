<template>
    <recess-modal :show-modal="isModalVisible" @close="close">
        <template slot="modal-title">
            <h3>{{ PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.PreviewPopup.MainTitle }}</h3>
        </template>
        <template slot="modal-body">
            <div v-if="isModalVisible">  
                <div class="row">
                    <div class="col-12"> 
                        <recess-divider class="pt-0" show-line />
                    </div>  
                    
                    <div class="col-12">
                        <h5>{{ PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.PreviewPopup.IntroductionText }} </h5>
                        <div class="qa-inhoud-popup-intro" v-html="localContentBlocks.contentInhoudDescriptions.intro"/>
                    </div>
                    <div class="col-12"> 
                        <recess-divider show-line />
                    </div>

                    <div class="col-12">
                        <h5>{{ PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.PreviewPopup.DescriptionText }} </h5>
                        <div class="qa-inhoud-popup-description" v-html="localContentBlocks.contentInhoudDescriptions.description"/>
                    </div> 
                </div>

                <div class="row">
                    <div class="col-12"> 
                        <recess-divider class="pt-0" show-line />
                    </div>

                    <div class="col-12" v-for="(contentBlock, index) in localContentBlocks.contentBlocksProp" :key="index">
                        <div v-if="contentBlock.heading && contentBlock.displayValue && contentBlock.body">                      
                      
                            <h5 class="qa-inhoud-popup-heading">{{ contentBlock.heading }}</h5> 
                            <div class="qa-inhoud-popoup-body m-0" v-html="contentBlock.body" />
                        
                            <recess-divider show-line />
                        </div>

                    </div>
                </div>
            </div>
        </template> 
        
    </recess-modal>
</template>

<script>    
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

export default {
    name: 'InhoudPreviewPopup',
    props: {
        isModalVisible: {
            type: Boolean,
            default: false
        },
        previewContentBlocks: {
            type: [Object, Array],
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            PROVIDER,
            localContentBlocks: {}
        }
    }, 
    watch: {
        isModalVisible: function watchPropsChange(newValue) {
            if (newValue === true) {
                this.localContentBlocks = this.previewContentBlocks 
            } else {
                this.localContentBlocks = {}
            }
        }
    }, 
    methods: {  
        close() {
            this.$emit('close') 
        } 
    }
}
</script>